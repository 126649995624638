import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import CTA from "../components/CTA";
import Explainer from "../components/Explainer";
import SEO from "../components/SEO";
import BaseLayout from "../components/layouts/BaseLayout";
import Features from "../components/Features";

const IndexPage = () => {
  const { manDocumentsAccident, screenshotCreateReport, bruggLiftingTeam } =
    useStaticQuery(
      graphql`
        query {
          manDocumentsAccident: file(
            relativePath: { eq: "man-documents-accident.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
            }
          }
          screenshotCreateReport: file(
            relativePath: { eq: "screenshot-create-report.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
            }
          }
          bruggLiftingTeam: file(
            relativePath: { eq: "brugg-lifting-team.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
            }
          }
        }
      `
    );

  return (
    <BaseLayout isHeroVisible={true}>
      <SEO />
      <Explainer
        title="Sicherheitsrelevante Vorkomnisse dokumentieren"
        paragraphs={[
          "Mit der Safety App können deine Mitarbeitenden jederzeit vom eigenen Smartphone oder Tablet aus sicherheitsrelevante Ereignisse dokumentieren. Die Berichte über Beinahe-Personenunfälle, Störungen oder Sachschäden können mit Bildern, Videos, Sprachmemos und der GPS-Position ergänzt werden.",
          "Nach dem Erfassen wird der Bericht in einer gemeinsamen Ablage in der Cloud mit deinem gesamten Team geteilt.",
        ]}
        image={screenshotCreateReport.childImageSharp.gatsbyImageData}
        imageAlt="Screenshot der Berichte Ansicht der Safety App"
        isImageLeft={true}
      />
      <Explainer
        title="Zukünftige Unfälle verhindern"
        paragraphs={[
          "Die für die Arbeitssicherheit zuständige Person in deinem Team wird über jeden erstellten Bericht benachrichtigt und zur Bearbeitung aufgefordert. So können Massnahmen zur Prävention zukünftiger Arbeitsunfälle definiert und umgesetzt werden.",
          "Zum Beispiel meldet jemand abstehende Nagelspitzen in alten Europaletten über die App. Die sicherheitsbeauftragte Person wird benachrichtigt und bindet die Entfernung dieser Nägel in den täglichen Arbeitsprozess ein.",
        ]}
        image={manDocumentsAccident.childImageSharp.gatsbyImageData}
        imageAlt="Für die Sicherheit zuständige Person überprüft neue Berichte, um zukünftige Unfälle zu verhindern"
        isImageLeft={false}
      />
      <Explainer
        title="Von Profis für Profis"
        paragraphs={[
          "Die Safety App wurde von der BRUGG Lifting AG entwickelt - einer der führenden Hersteller von Aufzugseilen, Architekturseilen und Drahtseilen, Zurr- & Hebemitteln. Aus eigener Praxiserfahrung wissen wir, welche Sicherheitsfunktionen für Profis wirklich wichtig sind. Gerne möchten wir unsere Lösung auch anderen Profis anbieten und helfen, für mehr Sicherheit und weniger Unfälle am Arbeitsplatz zu sorgen.",
        ]}
        image={bruggLiftingTeam.childImageSharp.gatsbyImageData}
        imageAlt="Das Team der BRUGG Lifting AG"
        isImageLeft={true}
      />
      <Features />
      {/* <Pricing /> */}
      <CTA />
      {/* <PosterDownload /> */}
    </BaseLayout>
  );
};

export default IndexPage;
